<template>
  <div>
    <p class="mb-6">Existencia por producto</p>
    <v-card>
      <v-card-text>
        <v-expand-transition>
          <div>
            <v-row>
              <v-col cols="6">
                <SelectMulti :outlined="true" :clearable="true" v-model="sucursalesSelect"></SelectMulti>
              </v-col>
              <v-col cols="6">
                <dos-fechas
                  :fechas="[filtros.Desde, filtros.Hasta]"
                  @getFechas="getFechas"
                  :icon="false"
                ></dos-fechas>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn class="mx-1 my-1" small :disabled="cargando" color="secondary" @click="limpiar()"> Limpiar </v-btn>
            <v-btn
              class="mx-1 my-1"
              :loading="cargando"
              small
              :disabled="cargando || !validar()"
              color="primary"
              @click="buscarDatos()"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaPaginadaReporte
          @getOpciones="getOpciones"
          @exportExcel="exportExcel"
          :acciones="['VER']"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL']"
          :otrasAcciones="otrasAcciones"
          :Cantidad="Cantidad"
          :Paginas="Paginas"
          :cargando="cargando"
        ></TablaPaginadaReporte>
      </v-card-text>
    </v-card>
  </div>
</template>
    
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiCheck, mdiCancel, mdiFilterOff, mdiFilter } from '@mdi/js'
import TablaPaginadaReporte from '@/components/TablaPaginadaReporte.vue'
import { computed, onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import config from '@/api/config'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import SelectMulti from '@/views/sistema/configuracion/sucursal/componentes/SelectMulti.vue'
import moment from 'moment'
import FacturaServices from '@/api/servicios/FacturaServices'
import SelectCategoria from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SelectLinea from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
// demos

export default {
  components: { TablaPaginadaReporte, DosFechas, SelectCategoria, SelectLinea, SelectMulti },
  setup() {
    const sucursalesSelect = ref([])
    const Paginas = ref(0)
    const Pagina = ref(1)
    const LimiteProps = ref(10)
    const Cantidad = ref(0)
    const showFiltros = ref(false)
    const NombreRef = ref(null)
    const filtros = ref({
      IdSucursal: store.state.sucursalSelect.id,
      IdArea: -1, 
      Desde: moment().format('YYYY-MM-DD'),
      Hasta: moment().format('YYYY-MM-DD'),
      Pagina: null,
      Limite: null,
      Columnas: null,
    })

    const getFechas = (datos)=>{
      filtros.value.Desde = datos.desde
      filtros.value.Hasta = datos.hasta
    }
    const items = [
      {
        value: 0,
        text: 'Todo',
      },
      {
        value: 1,
        text: 'Solo Existencia Positiva',
      },
      {
        value: 3,
        text: 'Solo Existencia 0',
      },
      {
        value: 2,
        text: 'Solo Existencia Negativa',
      },
      {
        value: 4,
        text: 'Solo Existencia Negativa y positivas',
      },
    ]
    const otrasAcciones = [
      {
        nombre: 'Activar',
        icon: mdiCheck,
        emit: 'GetBtn',
        color: 'success',
        ifValor: { columna: 'indActivo', valor: false },
      },
      {
        nombre: 'Desactivar',
        icon: mdiCancel,
        emit: 'GetBtn',
        color: 'error',
        ifValor: { columna: 'indActivo', valor: true },
      },
    ]
    const nombreReporte = ref(`Reporte existencia por producto ${moment().format('YYYYMMDDHHmmSS')}`)
    const cabecera = ref([
      { text: 'Id', value: 'idProducto', visible: true, columnaDB: 'Producto.id As ID' },
      {
        text: 'Sucursal',
        value: 'area.sucursal.nombre',
        visible: true,
        columnaDB: 'Sucursal.nombre As Sucursal',
      },
      { text: 'Area', value: 'area.nombre', width: '130px', visible: true, columnaDB: 'Area.nombre As Area' },
      {
        text: 'Referencia',
        value: 'producto.referencia',
        width: '160px',
        format: { small: true },
        visible: true,
        columnaDB: 'Producto.referencia As Referencia',
      },
      {
        text: 'Referencia fabrica',
        value: 'producto.referenciaFabrica',
        format: { small: true },
        visible: false,
        columnaDB: 'Producto.referenciaFabrica As [Referencia Fabrica]',
      },
      {
        text: 'Barra',
        value: 'producto.barra',
        format: { small: true },
        visible: false,
        columnaDB: 'Producto.barra As Barra',
      },
      {
        text: 'Producto',
        value: 'producto.nombre',
        width: '400px',
        align: 'left',
        format: { small: true },
        visible: true,
        columnaDB: 'Producto.nombre As Producto',
      } ,
      {
        text: 'Unidades Vendidas',
        value: 'totalUnidad',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalUnidad As [Unidades Vendidas]',
      }, 
      {
        text: 'Total Costo',
        value: 'totalCosto',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalCosto As [Total Costo]',
      }, {
        text: 'Total Costo2',
        value: 'totalCosto2',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalCosto2 As [Total Costo2]',
      }, {
        text: 'Total Costo3',
        value: 'totalCosto3',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalCosto3 As [Total Costo3]',
      },{
        text: 'Total Venta',
        value: 'totalVenta',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalVenta As [Total Venta]',
      },{
        text: 'Total Ganancia',
        value: 'totalGanancia',
        width: '120px',
        align: 'right',
        visible: true,
        format: { numeric: true },
        columnaDB: 'Movimiento.totalGanancia As [Total Ganancia]',
      },
      {
        text: 'Existencia Actual',
        value: 'inventario',
        width: '120px',
        align: 'right',
        visible: true, 
        columnaDB: 'Existencia.inventario As [Existencia Actual]',
      },
      
    ])
    const datos = ref([])
    const cargando = ref(false)

    const validar = () => {
      let validado = true

      return validado
    }

    // onBeforeMount(() => {
    //   buscarDatos()
    // })

    const getOpciones = opciones => {
      Pagina.value = opciones.Pagina
      LimiteProps.value = opciones.LimiteFilas
      buscarDatos()
    }

    const buscarDatos = () => {
      cargando.value = true

      FacturaServices.VentaProductoSucursalReportePaginado({
        ...filtros.value,
        IdSucursal: sucursalesSelect.value.join(','),
        Pagina: Pagina.value,
        Limite: LimiteProps.value,
      })
        .then(response => {
          if ((response.data.estatus = true)) {
            datos.value = response.data.datos.registros
            Paginas.value = response.data.datos.paginas
            Cantidad.value = response.data.datos.total
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const getVer = dato => {
      console.log(dato)
    }

    const exportExcel = columasSelect => {
      // console.log({...filtros.value,  Columnas: columasSelect})
      // console.log(`${config.api_master}producto/consultar/excel?${FuncionesGenerales.recorrerObjeto({...filtros.value,  Columnas: columasSelect})}`)
      window.open(
        `${config.api_master}factura/consultar/venta/producto/reporte/excel?${FuncionesGenerales.recorrerObjeto({
          ...filtros.value,
          IdSucursal: sucursalesSelect.value.join(','),
          Columnas: columasSelect,
        })}`,
      )
    }

    const limpiar = () => {
      datos.value = []
      filtros.value = {}
      sucursalesSelect.value = []
    }

    return {
      getVer,
      validar,
      buscarDatos,
      limpiar,
      exportExcel,
      showFiltros,
      NombreRef,
      Paginas,
      Pagina,
      Cantidad,
      LimiteProps,
      otrasAcciones,
      cabecera,
      datos,
      cargando,
      nombreReporte,
      filtros,
      FuncionesGenerales,
      sucursalesSelect,
      items,
      getOpciones,
      getFechas,
      icons: {
        mdiPencil,
        mdiFilterOff,
        mdiFilter,
        mdiCheck,
        mdiCancel,
      },
    }
  },
}
</script>
    